<template>
  <div class="row">
    <!--          <iframe id="idFrame" name="nameFrame" src="https://www.mystore.com/payment/form.php"/>-->
    <iframe width="100%" height="500px" name="nameFrame" src="http://127.0.0.1:8000/api/training-sessions/payment"    scrolling="no"  vads_action_mode />
    <form method="POST" action="https://sogecommerce.societegenerale.eu/vads-payment/" target="nameFrame" >
      <input @click="run" type="submit" name="card"></input>
    </form>
    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Permission List</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['permissions/create'])"
                       class="btn btn-info btn-wd"
                       :to="'/permissions/create'">
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>

        <div>
          <general-data-table
            ref="table"
            :api-url="'permissions/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">

              <template slot-scope="scope">

                <div v-if="column.value === 'slugs'">
                    <span v-for="slug in scope.row[column.value]">
                      {{ slug.permission_slug }}
                      <br>
                    </span>
                </div>

                <span v-else>{{ scope.row[column.value] }}</span>

              </template>

            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Actions">
              <template slot-scope="scope">
                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['permissions/update'])"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/permissions/edit/'+scope.row.id"><i
                  class="fa fa-edit"></i></router-link>

                <a v-if="$store.getters['auth/haveOneOfPermissions'](['permissions/delete'])"
                   v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>
          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this permission?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";


export default {
  components: {
    NLPagination,
    DeleteModal,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
        {label: 'Slugs', value: 'slugs', minWidth: '200', align: 'center'},
      ],

      deleteModalVisibility: false,
      toDeleteId: undefined
    }

  },

  methods: {
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete("permissions/delete", {headers: {}, data}).then((response) => {

        this.$notify({
          message: "Permission deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    run() {
      return;
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("training-sessions/update/" + this.id, this.formData);
        successMessage = "Training Session Updated Successfully";
      } else {
        request = this.axios.post("training-sessions/create", this.formData);
        successMessage = "Training Session Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/training-sessions/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          if (error.response.data.errors && error.response.data.errors['start_date']){
            this.$notify({
              message: error.response.data.errors['start_date'][0],
              timeout: 2000,
              type: 'danger'
            });
          }
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
  },
}
</script>
<style>
</style>
